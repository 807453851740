<!-- 
  Template:

  -----------------------------------------------------------

  for normal loading / only 1 pageLoading var:
  <PageLoader
    v-if="pageLoading" // optional, required if "v-else-if/v-else" is used for showing content
    :loading="pageLoading"> 
  <PageLoader>

  -----------------------------------------------------------
  
  for loading with duration display / which also have normal loading after the content is generated:
  <PageLoader
    v-if="pageLoading || duration < 100"

    :loading="pageLoading"
    
    :duration="duration"
    message="<...Kindly refer to later section...>">
  <PageLoader>
  
  # the duration must be -1 when generation of the content is completed
    or else the normal loading will not be displayed

  -----------------------------------------------------------

  Optional Fields:

  <PageLoader
  
    // replace "Kindly wait for ..." message in normal loading
    replace_msg="String"

    // replace "Kindly wait for ..." message in normal loading
    // & display the "Kindly wait for ..." with a <small> after the extra_msg
    extra_msg="String"

    // defined message to be displayed in duration loading
    // input String will lead to using default messages
        ## refer to vue data() for default messages ##
    // input Array that contains 2 Strings will result in customized message
        ## the first String is normal sized / the second String is <small> ##
    message="String | Array[2]"

    >
  <PageLoader>

 -->
<template>
  <div
    v-if="duration >= 0 && duration < 100"
    class="card border-0 h-100 p-3 rounded text-center justify-content-center"
  >
    <div>
      <div style="transform: scale(2)" class="orbit-spinner m-auto">
        <div class="orbit"></div>
        <div class="orbit"></div>
        <div class="orbit"></div>
      </div>

      <div class="row mt-5" v-if="duration >= 0">
        <div class="col">
          <h3 class="font-weight-bold text-blue">{{ duration }} %</h3>
        </div>
      </div>

      <div :class="['row', duration < 0 ? 'mt-5' : 'mt-3']">
        <div class="col">
          <h4 class="text-blue text-center font-weight-bold">
            {{ Array.isArray(message) ? message[0] : default_msg[message][0] }}
          </h4>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-3"></div>
        <div class="col">
          <small class="text-blue text-center">
            {{ Array.isArray(message) ? message[1] : default_msg[message][1] }}
          </small>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>

  <div v-else-if="loading" class="h-100 align-content-center">
    <div>
      <div class="loading-container">
        <div v-if="currentLoadingUI === 1" class="orbit-spinner m-auto mt-5" style="transform: scale(2);">
          <div class="orbit"></div>
          <div class="orbit"></div>
          <div class="orbit"></div>
        </div>

        <div v-if="currentLoadingUI === 2" class="rectangle-main">
          <section class="hero-section-small">
            <div class="square-small"></div>
            <div class="p-3">
              <div class="rectangle-small"></div>
              <div class="rectangle2-small"></div>
            </div>
          </section>
          <section class="hero-section">
            <div class="square"></div>
            <div class="p-3">
              <div class="rectangle"></div>
              <div class="rectangle2"></div>
            </div>
          </section>
        </div>

        <div v-if="currentLoadingUI === 3" class="file-main mt-5">
          <img class="file-icon" src="../../assets/icon/file-loading-icon.png" /> 
          <img class="file-icon-2" src="../../assets/icon/file-loading-icon.png" /> 
          <div class="magnifier-icon"></div>
        </div>

      </div>

      <div class="row mt-5" v-if="localCompletedPercentage >= 0">
        <div class="col">
          <h3 class="font-weight-bold text-center text-blue">{{ localCompletedPercentage }} %</h3>
        </div>

        <p v-if="localCompletedPercentage < 100" class="text-center text-blue font-weight-bold mt-2">
          {{ replace_msg ?? "" }}
        </p>

        <p v-else class="text-center text-blue font-weight-bold mt-2">
          Initializing…
        </p>
      </div>


      <div v-if="localCompletedPercentage < 100">
        <p class="text-center text-blue font-weight-bold">
          {{ extra_msg }}
        </p>
        <p class="text-center"> 
          <small v-if="showText" :class="['text-blue', 'typewriter', { 'blinking': isTextFullyDisplayed }]">
            {{ displayedText }}
          </small>
        </p>
      </div>

      <div v-else>
        <p class="text-center">
          <small :class="['text-blue', 'typewriter', { 'blinking': isTextFullyDisplayed }]">
            {{ displayedText }}
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    files_uploaded: {
      type: Number,
    },
    replace_msg: {
      type: String,
    },
    extra_msg: {
      type: String,
    },
    completedPercentage: {
      type: Number,
      required: true,
    },

    duration: {
      type: Number,
    },
    message: {
      type: [String, Array],
    },
  },

  data() {
    return {
      default_msg: {
        generating: [
          "Generating Content",
          "Please wait a moment while we process it. This may take a few seconds.",
        ],
        generatingLongerContent: [
          "Generating Content",
          "Rest assured, we've got this covered, This may take 2 to 3 minutes.",
        ],
        uploading: [
          "Uploading case-law.pdf",
          "This may take some time depending on the size the file and your internet connection speed.",
        ],
      },
      showText: false,
      fullText: "This may take some time depending on the size of the file and your internet connection speed.",
      displayedText: "",
      localCompletedPercentage: this.completedPercentage,
      currentLoadingUI: 1,
    };
  },

  mounted() {
    this.incrementPercentage();
    setTimeout(() => {
      this.showText = true;
    }, 0); // Display text after 5 seconds

    if (this.showText) {
      this.typeWriter();
    }

    setInterval(() => {
      this.currentLoadingUI = (this.currentLoadingUI % 3) + 1;
    }, 12000); // Change loading UI every 10 seconds
  },

  computed: {
    isTextFullyDisplayed() {
      return this.displayedText === this.fullText;
    },
  },
  
  watch: {
    showText(newVal) {
      if (newVal) {
        this.typeWriter();
      }
    },
    completedPercentage(newVal) {
      this.localCompletedPercentage = newVal;
    },
  },

  methods: {
    incrementPercentage() {
      const interval = setInterval(() => {
        if (this.localCompletedPercentage < 99) { // Ensure it doesn't exceed 99%
          this.localCompletedPercentage = parseFloat((this.localCompletedPercentage + 0.77).toFixed(2));
        } else {
          clearInterval(interval);
        }
      }, 3000); // Increment every 3 second
    },

    typeWriter() {
      // Set fullText based on localCompletedPercentage
      if (this.localCompletedPercentage >= 0 && this.localCompletedPercentage < 5) {
        this.fullText = "Starting the campaign setup, hang tight...";
      }
      else if (this.localCompletedPercentage >= 5 && this.localCompletedPercentage < 25) {
        this.fullText = "We're starting the process.";
      } 
      else if (this.localCompletedPercentage >= 25 && this.localCompletedPercentage < 50) {
        this.fullText = "The upload is underway. Hang tight, we're making progress!";
      } 
      else if (this.localCompletedPercentage >= 50 && this.localCompletedPercentage < 75) {
        this.fullText = "Processing the data now. This may take some time depending on the file size and your internet speed.";
      } 
      else if (this.localCompletedPercentage >= 75 && this.localCompletedPercentage < 90) {
        this.fullText = "We're nearly done! Just a little longer...";
      }
      else if (this.localCompletedPercentage >= 90 && this.localCompletedPercentage < 100) {
        this.fullText = "Finalizing everything, almost there...";
      }
      else if (this.localCompletedPercentage >= 100) {
        this.fullText = "Launching...";
      } 
      // else if (this.localCompletedPercentage >= 100) {
      //   this.fullText = "Launching...";
      // } 
      else {
        this.fullText = "";
      }

      // Immediately set displayedText to fullText
      this.displayedText = this.fullText;

      // Clear displayedText immediately if localCompletedPercentage is >= 100
      if (this.localCompletedPercentage >= 100) {
        this.displayedText = '';
      } else {
        // Clear displayedText and rerun the typewriter effect after 5 seconds
        setTimeout(() => {
          this.displayedText = '';
          this.typeWriter();
        }, 200);
      }
    },
  }
};
</script>

<style scoped>
/*Orbit Loading Animation*/
.loading-container {
  height: 120px; /* Set a fixed height for the loading container */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* orbitter */
.orbit-spinner,
.orbit-spinner * {
  box-sizing: border-box;
}

.orbit-spinner {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  perspective: 800px;
}

.orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
  border-bottom: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
  border-right: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
  border-top: 3px solid #094890;
}

@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.text-center {
  transition: all 0.3s ease-in-out;
  animation: background-fade 3s infinite;
  font-size: 1.0rem;
  margin-bottom: 5px;
}

.typewriter {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid rgb(255, 255, 255); /* Cursor effect */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: rgb(255, 255, 255); }
}

.blinking {
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

<style scoped>
/* Rectangle Loading Animation */
.rectangle-main {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  width: 50px;
  height: 50px;
  background-color: rgb(232,232,232);
  animation: popOut 0.65s infinite;
  box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
  border-radius : 8px;
  flex-shrink: 0;
  }

  @keyframes popOut {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 1;
  }
  }

.rectangle {
width: 80px;
height: 15px;
background-color: rgb(232,232,232);
animation: popOutExpand 0.65s infinite;
transform-origin: left center;
margin-bottom: 10px;
box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
border-radius : 1px;
}

.rectangle2 {
width: 100px;
height: 15px;
background-color: rgb(232,232,232);
animation: popOutExpand2 0.65s infinite;
transform-origin: left center;
box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
border-radius : 1px;
}

@keyframes popOutExpand {
0% {
  transform: scaleX(1);
  opacity: 0;
}
25% {
  transform: scaleX(1);
  opacity: 1;
}
50% {
  transform: scaleX(1.5);
  opacity: 1;
}
100% {
  transform: scaleX(1.5);
  opacity: 1;
}
}

@keyframes popOutExpand2 {
0% {
  transform: scaleX(0);
  opacity: 0;
}

100% {
  transform: scaleX(1.5);
  opacity: 1;
}
}

.hero-section-small {
  padding-left: 45px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideInUp 0.65s forwards infinite;
}

@keyframes slideInUp {
  0% {
      transform: translateY(20%);
      opacity: 1;
  }
  20% { /* Compress the movement into the first 20% of the timeline */
      transform: translateY(0);
      opacity: 1;
  }
  100% {
      transform: translateY(-10px); /* Move it a bit up and stay there */
      opacity: 1;
  }
  }

.square-small {
  width: 40px;
  height: 40px;
  background-color: rgb(232,232,232);
  box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
  border-radius : 8px;
  flex-shrink: 0;
  }

.rectangle-small {
width: 90px;
height: 13px;
background-color: rgb(232,232,232);
transform-origin: left center;
margin-bottom: 10px;
box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
border-radius : 1px;
}

.rectangle2-small {
width: 100px;
height: 13px;
background-color: rgb(232,232,232);
transform-origin: left center;
box-shadow: 0 0 10px rgba(14, 68, 133, 0.5);
border-radius : 1px;
}

</style>


<style scoped>
/* Loading File Animation */
.file-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height */
  position: relative;
}
.file-icon {
  width: 60px;
  height: 65px;
  border-radius: 8px;
  position: absolute;
  animation: move-left 1.5s linear infinite;
}
.file-icon-2 {
  width: 60px;
  height: 65px;
  border-radius: 8px;
  position: absolute;
  opacity: 0; /* Initially hidden */
  animation: move-left 1.5s linear infinite;
  animation-delay: 0.75s; /* Delayed animation */
  
}

@keyframes move-left {
  0% {
    transform: translateX(100%) scale(0.8);
    opacity: 0;
  }
  0% {
    transform: translateX(100%) scale(0.8);
    opacity: 1;
  }
  20% {
    transform: translateX(0%);
    opacity: 1;
  }
  40% {
    transform: translateX(0%);
    opacity: 1;
  }
  60% {
    transform: translateX(-130%) scale(0.9);
    opacity: 1;
  }
  80% {
    transform: translateX(-130%) scale(0.9);
    opacity: 1;
  }
  81% {
    opacity: 1; /* Instantly disappear */
  }

  90% {
    opacity: 1; /* Instantly disappear */
  }

  91% {
    opacity: 0; /* Instantly disappear */
  }
  100% {
    transform: translateX(-130%) scale(0.9);
    opacity: 0;
  }
}

.magnifier-icon {
  width: 68px; /* Increased size */
  height: 68px; /* Increased size */
  border: 6px solid rgb(200,200,200);
  border-radius: 50%;
  position: absolute;
}

.magnifier-icon::after {
  content: '';
  width: 20px; /* Adjusted size */
  height: 10px; /* Adjusted size */
  background: rgb(170,170,170);
  position: absolute;
  top: 50px; /* Moved down */
  left: 54px; /* Adjusted position */
  transform: rotate(49deg);
  transform-origin: 0 0;
  border-top-right-radius: 10px; 
  border-bottom-right-radius: 10px;
}
</style>
